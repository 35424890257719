"use strict";
/**
 * ==================================================================================
 * Description:       desc
 * Creation Date:     8/3/2016
 * Author:            AMcDaniel
 * ==================================================================================
 */

if(typeof exports == "undefined")
{
    var exports;
}
var shared_model_utils = exports || {};
var general_utils;
if((typeof require != "undefined") && require && (typeof require == "function")) { //Coded this way because it has to work client-side and server-side
    general_utils = require('./general_utils');
}


function convert_results_to_calendar_fields(calendar_fields_array, entity_inst_array, attributes, for_client_side) {
    var ret_val = [];
    var entity_instance, value;
    var caption_fields = attributes.caption_fields;

    for (var i = 0, u = entity_inst_array.length; i<u;i++){
        entity_instance = entity_inst_array[i];
        for (var j = 0, v = calendar_fields_array.length; j < v; j++){
            var single_set_of_calendar_fields = calendar_fields_array[j];
            value = {
                record:entity_instance,
                resources:[entity_instance[single_set_of_calendar_fields.resources_field] || 'none'],
                text:single_set_of_calendar_fields.caption_field?entity_instance[single_set_of_calendar_fields.caption_field]:make_friendly_caption(caption_fields, entity_instance, attributes.attributes) + ' ' + attributes.field_paths[single_set_of_calendar_fields.start_datetime_field].caption,
                allDay:false
            };
            if (entity_instance[single_set_of_calendar_fields.recurrence_field]){
                value.recurrenceRule = entity_instance[single_set_of_calendar_fields.recurrence_field];
                if(for_client_side && value.recurrenceRule) {
                    value.recurrenceRule = fix_recurrence_rule_format(value.recurrenceRule);
                }
                value.startDate = entity_instance[single_set_of_calendar_fields.start_datetime_field];
            }
            else{
                value.startDate = entity_instance[single_set_of_calendar_fields.start_datetime_field];
                value.endDate = entity_instance[single_set_of_calendar_fields.end_datetime_field];
                if (!value.endDate){
                    value.endDate = new Date(value.startDate.getTime() + 1800000);
                }
            }
            ret_val.push(value);
        }
    }
    return ret_val;
}


function get_attribute_by_field_path(attribute_arr, field_path) {
    if(!attribute_arr || (attribute_arr.length == 0)) {
        return null;
    }
    for(var i = 0; i < attribute_arr.length; i++) {
        var attribute = attribute_arr[i];
        if(attribute && attribute.field_path && (attribute.field_path == field_path)) {
            return attribute;
        }
    }
}

function get_caption_for_single_field(attribute, field_value) {
    if(field_value != null) {
        if (typeof field_value == "object") {
            if (field_value.caption) { //use caption if defined
                field_value = field_value.caption;
            }
            else {
                field_value = null;
            }
        }
        else if (attribute) {
            if(attribute.list_of_values && Array.isArray(attribute.list_of_values)) {
                var index = attribute.list_of_values.map(function(kv) {return kv.code}).indexOf(field_value);
                if(index == -1) {
                    field_value = null;
                }
                else {
                    field_value = attribute.list_of_values[index].value;
                }
            }
            else if (attribute.db_type == "Date") {
                field_value = new Date(field_value).toDateString();
            }
        }
    }
    return field_value;
}

function make_friendly_caption(caption_fields, entity_values, attribute_arr) {
    var ret_val = "";
    for(var i = 0; i < caption_fields.length; i++) {
        var complex_path = caption_fields[i];
        var field_value = entity_values[complex_path];
        if(field_value != null) {
            var attribute = get_attribute_by_field_path(attribute_arr, complex_path);
            field_value = get_caption_for_single_field(attribute, field_value);
            if (field_value != null) {
                ret_val = ((ret_val) ? ret_val + " " + field_value : field_value);
            }
        }
    }
    return ret_val;
}

/**
 * Currently, the calendar does not function if the RRULE value is preceeded by "RRULE:" or if it has a BYSETPOS value specified
 * @param rrule_string
 * @returns {*|string}
 */
function fix_recurrence_rule_format(rrule_string) {
    if(rrule_string) {
        var ret_string = remove_rrule(rrule_string);
        var index_of_bysetpos = ret_string.indexOf(";BYSETPOS=1");
        if (index_of_bysetpos != -1) {
            ret_string = ret_string.substring(0, index_of_bysetpos) + ret_string.substring(index_of_bysetpos + ";BYSETPOS=1".length);
        }
        return ret_string;
    }
}


/**
 * Small helper function to remove the "RRULE:" from the rrule string
 * @param rrule_string
 * @returns {*|string}
 */
function remove_rrule(rrule_string) {
    if(rrule_string && (rrule_string.indexOf("RRULE:") == 0)) {
        rrule_string = rrule_string.substring(6);
    }
    return rrule_string;
}

/**
 * Makes a code that is shared between client and server for storing personalizations to app_object layouts (currently data_list only)
 * @param app_object_code
 * @param app_object_usage
 * @param is_mobile
 * @returns {string}
 */
function make_personalized_storage_code(app_object_code, app_object_usage, is_mobile) {
    var ret_val = app_object_code + ((app_object_usage) ? ("_" + app_object_usage) : "");
    if(is_mobile) {
        ret_val = ret_val + "__mobile";
    }
    return ret_val;
}

function make_conditions_using_map_fields(map_fields, coordinate_bounds) {
    if(!map_fields || !map_fields.length) {
        return null;
    }
    if(!coordinate_bounds || !Object.keys(coordinate_bounds).length) {
        return null;
    }
    var ret_val_or_array = [];
    for(var i = 0; i < map_fields.length; i++) {
        var map_fields_object = map_fields[i];
        if(map_fields_object.coordinates_field) { //only include map fields that specify a coordinates field
            var this_conditions_object_lat_min = {};
            this_conditions_object_lat_min[map_fields_object.coordinates_field + ":latitude"] = {$gt: coordinate_bounds.lat_min};
            var this_conditions_object_lat_max = {};
            this_conditions_object_lat_max[map_fields_object.coordinates_field + ":latitude"] = {$lt: coordinate_bounds.lat_max};
            var this_conditions_object_lng_min = {};
            this_conditions_object_lng_min[map_fields_object.coordinates_field + ":longitude"] = {$gt: coordinate_bounds.lng_min};
            var this_conditions_object_lng_max = {};
            this_conditions_object_lng_max[map_fields_object.coordinates_field + ":longitude"] = {$lt: coordinate_bounds.lng_max};
            ret_val_or_array.push({$and:[this_conditions_object_lat_min, this_conditions_object_lat_max, this_conditions_object_lng_min, this_conditions_object_lng_max]});
        }
    }
    if(!ret_val_or_array.length) {
        return null;
    }
    return {$or: ret_val_or_array};
}

/**
 * A helper function for field_paths which contain arrays, to get the "field_path" ( not field_name, which would return text after the last ":" ) from the deepest array'd entity's point of view.
 * @param field_path
 * @returns {*}
 */
function get_local_field_path_from_full_path(field_path) {
    if(!field_path || (field_path == "") || (typeof field_path != "string")) {
        return null;
    }
    var last_index = field_path.lastIndexOf("]:");
    if(last_index == -1) {
        return field_path;
    }
    if((last_index + 2) >= field_path.length) {
        return null;
    }
    return field_path.substring(last_index + 2);
}

function get_friendly_entity_caption_from_entity_attributes(entity_attributes, entity_name, options) {
    options = general_utils.set_options(options, {
        plural: false
    }, true);

    if (options.plural) {
        return entity_attributes.caption_plural || entity_attributes.name_plural || entity_attributes.entity || entity_name;
    }
    return entity_attributes.caption_singular || entity_attributes.name_singular || entity_attributes.entity || entity_name;
}

function make_aggregation_chart_title(entity_attributes, y_axis_fields, x_axis_fields, chart_color_keys, translate_function) {
    var field_paths = entity_attributes.field_paths || {};
    var aggregation_types = [{caption: translate_function("label_column_chooser_sum") || "Sum", value: "sum"}, {caption: translate_function("label_column_chooser_min") || "Min", value: "min"}, {caption: translate_function("label_column_chooser_max") || "Max", value: "max"}, {caption: translate_function("label_column_chooser_ave") || "Avg", value: "avg"}]; //See: pivot-chart-utils.js, aggregation_types
    var local_get_field_caption_function = function(a) {
        var ret_val;
        var field_path = pivot_grid_data_filter_and_map_function(a);
        if(field_path && field_paths[field_path]) {
            ret_val = field_paths[field_path].caption;
        }
        return ret_val || field_path;
    };

    var y_axis_field_caption_arr = (y_axis_fields || []).filter(pivot_grid_data_filter_and_map_function).map(get_aggregation_field_caption.bind(this, aggregation_types, translate_function, local_get_field_caption_function)).filter(general_utils.dummy_filter_function);


    var x_axis_field_caption_arr = (x_axis_fields || []).filter(pivot_grid_data_filter_and_map_function).map(local_get_field_caption_function).filter(general_utils.dummy_filter_function);
    var chart_color_keys_field_caption_arr = (chart_color_keys || []).filter(pivot_grid_data_filter_and_map_function).map(local_get_field_caption_function).filter(general_utils.dummy_filter_function);

    return make_aggregation_chart_title_helper(shared_model_utils.get_friendly_entity_caption_from_entity_attributes(entity_attributes, entity_attributes.entity, {plural: true}), y_axis_field_caption_arr, x_axis_field_caption_arr, chart_color_keys_field_caption_arr, translate_function);
}

function get_aggregation_field_caption(aggregation_types, translate_function, local_get_field_caption_function, summary_field_obj) {
    var field_path = pivot_grid_data_filter_and_map_function(summary_field_obj);
    if (field_path == "__record_count") {
        return translate_function("custom_aggregation_caption_record_count");
    }
    var field_caption = local_get_field_caption_function(summary_field_obj);
    if(!field_caption || (field_caption == "")) {
        return null;
    }
    var agg_type = summary_field_obj.summaryType;
    if(!agg_type || (agg_type == "")) {
        return field_caption
    }
    var agg_type_info = general_utils.first_where(aggregation_types, function(a) {
        return (a && (a.value == agg_type));
    });
    if(agg_type_info && agg_type_info.caption) {
        field_caption = field_caption + "(" + agg_type_info.caption + ")"
    }
    return field_caption;
}

/**
 * Helper function for making a pivot chart title
 * @param entity_caption
 * @param summary_fields_captions
 * @param x_field_captions
 * @param chart_color_keys_captions
 * @param translate_function
 * @returns {*}
 */
function make_aggregation_chart_title_helper(entity_caption, summary_fields_captions, x_field_captions, chart_color_keys_captions, translate_function) {
    //summary_fields_captions[0] "and" summary_fields_captions[1] "and" summary_fields_captions[...]
    // "By"
    //x_field_captions[0] (then x_field_captions[1], then x_field_captions[..])
    // "and"
    //chart_color_keys_captions[0] (then chart_color_keys_captions[1], then chart_color_keys_captions[..])
    var ret_val;
    var summary_fields_string = general_utils.make_string_from_array(summary_fields_captions, (translate_function("custom_dashboard_part_separator_for_separate_summary_parts") || " and "));
    var x_fields_string = make_aggregation_chart_title_groups_helper(x_field_captions, translate_function);
    var chart_color_keys_string = make_aggregation_chart_title_groups_helper(chart_color_keys_captions, translate_function);

    if(summary_fields_string) {
        ret_val = entity_caption + (translate_function("custom_dashboard_part_separator_for_title_and_summary_parts") || ": ") + summary_fields_string
    }
    else {
        ret_val = entity_caption;
    }

    if(x_fields_string || chart_color_keys_string) {
        ret_val = ret_val + (translate_function("custom_dashboard_part_separator_for_title_and_first_grouping") || " By ");
        if(x_fields_string) {
            ret_val = ret_val + x_fields_string;
        }
        if(x_fields_string && chart_color_keys_string) {
            ret_val = ret_val + (translate_function("custom_dashboard_part_separator_for_first_grouping_and_second_grouping") || " And ");
        }
        if(chart_color_keys_string) {
            ret_val = ret_val + chart_color_keys_string;
        }
    }

    return ret_val;
}

function make_aggregation_chart_title_groups_helper(group_field_captions, translate_function) {
    var group_fields_string;
    if(group_field_captions && group_field_captions.length) {
        group_fields_string = group_field_captions[0];
        group_field_captions = group_field_captions.slice(1);
        if(group_field_captions && group_field_captions.length) {
            group_fields_string = group_fields_string + "(" + (translate_function("custom_dashboard_part_prefix_for_separate_grouping_parts") || "then ") + general_utils.make_string_from_array(group_field_captions, (translate_function("custom_dashboard_part_separator_for_separate_grouping_parts") || ", then ")) + ")";
        }
    }
    return group_fields_string;
}

function pivot_grid_data_filter_and_map_function(a) {
    return a.dataField;
}


/** Adds a condition to an existing set of conditions using $and
 *
 * @param conditions
 * @param and_clause
 * @returns {*}
 */
function add_and_clause_to_conditions(conditions, and_clause) {
    if (!conditions && !and_clause) {
        return {};
    }

    if(!conditions || (Object.keys(conditions).length == 0)) {
        return and_clause;
    }
    if(!and_clause || (Object.keys(and_clause).length == 0)) {
        return conditions;
    }
    if(conditions["$and"] && Array.isArray(conditions["$and"])) {
        conditions["$and"].push(and_clause);
        return conditions;
    }
    else if(and_clause["$and"] && Array.isArray(and_clause["$and"])) {
        and_clause["$and"].push(conditions);
        return and_clause;
    }

    var ret_conditions = {};
    ret_conditions["$and"] = [];
    ret_conditions["$and"].push(conditions);
    ret_conditions["$and"].push(and_clause);
    return ret_conditions;
}


/** Adds a condition to an existing set of conditions using $or
 *
 * @param or_conditions
 * @param or_clause
 * @returns {*}
 */
function add_or_clause_to_conditions(or_conditions, or_clause) {
    if (!or_conditions && !or_clause) {
        return {};
    }

    if (!or_conditions || (Object.keys(or_conditions).length == 0)) {
        return or_clause;
    }
    if (!or_clause || (Object.keys(or_clause).length == 0)) {
        return or_conditions;
    }

    if(or_conditions["$or"] && Array.isArray(or_conditions["$or"])) {
        or_conditions["$or"].push(or_clause);
        return or_conditions;
    }
    else if(or_clause["$or"] && Array.isArray(or_clause["$or"])) {
        or_clause["$or"].push(or_clause);
        return or_clause;
    }

    var ret_conditions = {};
    ret_conditions["$or"] = [];
    ret_conditions["$or"].push(or_conditions);
    ret_conditions["$or"].push(or_clause);
    return ret_conditions;
}

function remove_invalid_html_from_field(source) { // This function was added to remove Microsoft tags being left by the editor (on copy/paste?)
    var html = source + ""; //Ensure source is a string
    var regX = new RegExp("<(?:!(?:--[\\s\\S]*?--\\s*)?(>)\\s*|(?:script|style)[\\s\\S]*?<\\/(?:script|style)>)", "gi"); //Completely eliminate these tags and their contents
    var retVal = html.replace(regX, function(m, b){ return b?'':m; });

    retVal = replaceTagsWith(retVal, ["wbr","pre","ins","blockquote","textarea"], "span");

    //Eliminating strange font-family artifacts -may need to debug above statements to make sure they aren't causing this, but for now:
    retVal = retVal
    //TODO: Make this font logic generic?  Something like: /(\s+|\"+)\S*&amp;quot;,(sans-)?serif(;)?/gi, function(m, b) { return '"font-family:serif' or 'font-family:serif' or 'font-family:{dynamic}' ? }
    .replace(/verdana&amp;quot;,sans-serif/g,"font-family: verdana;")
    .replace(/&amp;amp;quot&amp;quot;,serif/g,"font-family: serif;")
    .replace(/arial&amp;quot;,sans-serif;/g,"font-family: arial;")
    .replace(/calibri&amp;quot;,sans-serif;/g,"font-family: calibri;")
    //replace some values that were causing template crash:
    .replace(/#ffff00/g, "yellow")
    .replace(//g, "-"); //This character is NOT A SPACE, this is is 0x1, or in HTML: &#1;  and it breaks docx templating

    return retVal;
}

function replaceTagsWith(retVal, tags, newTag) {
    const tagPattern = tags.join('|');

    // Replace opening tags with a new tag (preserving attributes if any)
    let regX = new RegExp("<\\s?(" + tagPattern + ")\\s?>", "gi"); //Replace these tags with spans (this matches opening tags)
    retVal = retVal.replace(regX, function(m, b){ return '<' + newTag + '>'; });
    regX = new RegExp("<\\s?(" + tagPattern + ")\\s", "gi"); //Replace these tags with spans (this matches opening tags)
    retVal = retVal.replace(regX, function(m, b){ return '<' + newTag + ' '; });
    regX = new RegExp("<\\s?\\/?(" + tagPattern + ")\\s?>", "gi"); //Replace these tags with spans (this matches closing tags)
    retVal = retVal.replace(regX, function(m, b){ return '</' + newTag + '>'; });
    regX = new RegExp("<(" + tagPattern + ")\\s*\\/?>", "gi"); //Eliminate remaining tags (this matches empty tags)
    retVal = retVal.replace(regX, function(m, b){ return ''; });
    
    return retVal;
}


shared_model_utils.convert_results_to_calendar_fields = convert_results_to_calendar_fields;
shared_model_utils.make_personalized_storage_code = make_personalized_storage_code;
shared_model_utils.make_conditions_using_map_fields = make_conditions_using_map_fields;
shared_model_utils.get_local_field_path_from_full_path = get_local_field_path_from_full_path;
shared_model_utils.get_friendly_entity_caption_from_entity_attributes = get_friendly_entity_caption_from_entity_attributes;
shared_model_utils.make_aggregation_chart_title = make_aggregation_chart_title;
shared_model_utils.pivot_grid_data_filter_and_map_function = pivot_grid_data_filter_and_map_function;
shared_model_utils.add_and_clause_to_conditions = add_and_clause_to_conditions;
shared_model_utils.add_or_clause_to_conditions = add_or_clause_to_conditions;
shared_model_utils.remove_invalid_html_from_field = remove_invalid_html_from_field;
