/**
 * ========================================================
 * Description: A web control for editing a text field which
 *              can contain html
 * Creation Date: ?
 * Author: ?
 * ========================================================
 **/

function removeHtmlHiddenContent(html) {
    //Wanted to do this server-side like using shared_model_utils.remove_invalid_html_from_field, but html sections are very difficult to parse without a proper DOM parser, and serverside is old so even a DOM parsing node_module might break with new tags, so I have no choice but to do this client-side, I feel:
    
    // Create a temporary container element (not appended to the DOM)
    var container = document.createElement('div');
    container.innerHTML = html;

    function removeHidden(node) {
        if (node.nodeType === 1) { // Element node
            var style = node.getAttribute('style');
            if (style && (/display\s*:\s*none|visibility\s*:\s*hidden/i).test(style)) {
                node.parentNode.removeChild(node);
                return; // Node removed, no need to check its children
            }
            var children = node.childNodes;
            // Iterate backwards to safely remove nodes
            for (var i = children.length - 1; i >= 0; i--) {
                removeHidden(children[i]);
            }
        }
    }

    removeHidden(container);

    var cleanedHtml = container.innerHTML;

    // Clean up the container reference
    container = null;

    return cleanedHtml;
}

var EditorHtml = React.createClass({
    render: function() {
        return <div ref="domElement" />;
    },
    componentDidMount:function()
    {
        var context = this;
        context.oldValue = this.props.value;
        $(this.refs.domElement).html(this.props.value).tinymce({
            readonly:this.props.readOnly,
            plugins: "textcolor",
            invalid_styles: 'color font-size font-family',
            toolbar: 'undo redo | styleselect | bold italic backcolor | bullist | link image',
            textcolor_map: [
                "ffff00", "Yellow"
            ],
            menubar: false,
            setup : function(ed){
                ed.on('change', function(e){
                    var content = ed.getContent();
                    content = removeHtmlHiddenContent(content);
                    if (context.oldValue == content) {
                        return;
                    }
                    context.oldValue = content;
                    context.props.onChange(content);
                    context.props.onBlur();
                });
            }
        });
    }
});
